import React, { useState } from "react";
import "./FileShow.scss";
import FileSaver from "file-saver";
import JSZip, { files } from "jszip";
import Edit from "../../Assets/Edit.png";
import Save from "../../Assets/Download.png";
import Delete from "../../Assets/Delete.png";
import Check from "../../Assets/Check.png";

import { useNavigate } from "react-router-dom";

import jQuery from "jquery";

import * as XLSX from "xlsx/xlsx.mjs";

/* load 'fs' for readFile and writeFile support */
import { read, writeFileXLSX } from "xlsx";
/* load the codepage support library for extended support with older formats  */
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";

import editIcon from "../../Assets/edit_icon.png"
import { isYearAndMonthViews } from "@mui/x-date-pickers/DatePicker/shared";

set_cptable(cptable);

function FileShow2({
  file,
  setData,
  setStep,
  fileName,
  fileData,
  setFileName,
  invoiceNumber,
  errorList,
  setNameMasterCode,
  customDate,
  openModal,
  resetUpload
}) {
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const [fileDisplay, setFileDisplay] = useState(fileName);
  const [fileNum, setFileNum] = useState(fileName.length);

  const [deleteWarn, setDeleteWarn] = useState(0);

  const getUserRole = () => {
    return localStorage.getItem("role") === "admin" ? true : false;
  };

  const [isAdmin, setIsAdmin] = useState(getUserRole())

  // const navigate = useNavigate();

  const changeSelect = () => {
    setSelectAll((prev) => !prev);
  };

  const EditFile = (name) => {
    setData(fileData.get(name));
    setNameMasterCode(name);
  };

  const deleteAll = () => {
    setFileDisplay([]);
    setFileNum("0");
  };

  // returns a copy of a json of diffrent pointer
  function copy(aObject) {
    // Prevent undefined objects
    // if (!aObject) return aObject;

    let bObject = Array.isArray(aObject) ? [] : {};

    let value;
    for (const key in aObject) {
      // Prevent self-references to parent object
      // if (Object.is(aObject[key], aObject)) continue;

      value = aObject[key];

      bObject[key] = typeof value === "object" ? copy(value) : value;
    }

    return bObject;
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const downloadAll = () => {
    const date = new Date();
    setDeleteWarn(0);
    var zip = new JSZip();
    var iterate = 0;
    for (var a of fileName) {
      const data = npgZip(fileData.get(a), a, iterate);
      let wbout = XLSX.write(data, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });

      zip.file(a + makeid(5) + "_.xlsx", wbout, { binary: true });
      iterate++;
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      FileSaver.saveAs(content, `data_${date.toLocaleDateString()}.zip`);
    });
  };

  const downloadAllinASingleFile = () => {
    let invoiceAdder;
    let i = 0;
    const localData = [];
    const m = []
    for (var d of fileName) {
      let djson = fileData.get(d);
      invoiceAdder = parseInt(invoiceNumber) + parseInt(i);
      for (var j of djson) {
        delete j["XCode"];
        // j["InvoiceNo"] = j["InvoiceNo"] + "-" + invoiceAdder;
        // j["InvoiceDate"] = customDate;

      }
      for (const j of djson) {
        const l = []
        for (const iterator of j.data) {
          const obj = {
            "Invoice Number": iterator["InvoiceNo"],
          "Customer": iterator["provider_name"]?.trim()?.split(" ")[0],
          "Invoice Date": iterator["InvoiceDate"],
          "Due Date": iterator["DueDate"],
          "Terms": iterator["Terms"],
          "Location": iterator["Location"],
          "Item Description 1": iterator["ItemDescription1"],
          "Item Description 2": iterator["ItemDescription2"],
          "Service Date": iterator["available_date"],
          "Taxable": iterator["Taxable"],
          "Item Quantity": iterator["ItemQuantity"],
          "Rate": iterator["Rate"],
          "Item Amount": iterator["Amount"],

          }
          l.push(obj)
        }
        m.push(...l)
      }
      i++;
      localData.push(...djson);
    }
    console.log(m);
    var worksheet = XLSX.utils.json_to_sheet(m);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `data.xlsx`);
  };

  const npgZip = (jsonArray, name, index) => {
    let djson = copy(jsonArray);
    const invoiceAdder = parseInt(index) + parseInt(invoiceNumber);
    const m = [];
    for (var j of djson) {
      delete j["XCode"];
      const l = []
      for (const iterator of j.data) {
        const obj = {
          "Invoice Number": iterator["InvoiceNo"],
          "Customer": iterator["provider_name"]?.trim()?.split(" ")[0],
          "Invoice Date": iterator["InvoiceDate"],
          "Due Date": iterator["DueDate"],
          "Terms": iterator["Terms"],
          "Location": iterator["Location"],
          "Item Description 1": iterator["ItemDescription1"],
          "Item Description 2": iterator["ItemDescription2"],
          "Service Date": iterator["available_date"],
          "Taxable": iterator["Taxable"],
          "Item Quantity": iterator["ItemQuantity"],
          "Rate": iterator["Rate"],
          "Item Amount": iterator["Amount"],

        }
        l.push(obj)
      }
      m.push(...l)
      // j["InvoiceNo"] = j["InvoiceNo"] + "-" + invoiceAdder;
      // j["InvoiceDate"] = customDate;
    }
    var worksheet = XLSX.utils.json_to_sheet(m);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    return wb;
  };

  const download = (jsonArray, name, index) => {
    console.log(jsonArray);
    let djson = copy(jsonArray);
    const m = [];
    for (var j of djson) {
      delete j["XCode"];
      const l = []
      for (const iterator of j.data) {
        const obj = {
          "Invoice Number": iterator["InvoiceNo"],
          "Customer": iterator["provider_name"]?.trim()?.split(" ")[0],
          "Invoice Date": iterator["InvoiceDate"],
          "Due Date": iterator["DueDate"],
          "Terms": iterator["Terms"],
          "Location": iterator["Location"],
          "Item Description 1": iterator["ItemDescription1"],
          "Item Description 2": iterator["ItemDescription2"],
          "Service Date": iterator["available_date"],
          "Taxable": iterator["Taxable"],
          "Item Quantity": iterator["ItemQuantity"],
          "Rate": iterator["Rate"],
          "Item Amount": iterator["Amount"],
          // "Provide Name": iterator["provider_name"],
          // "Practitioner Name": iterator["practitioner_name"],
        }
        l.push(obj)
      }
      // j["InvoiceNo"] = j["InvoiceNo"] + "-" + invoiceAdder;
      // delete j["Invoice Date"];
      m.push(...l)
    }
    console.log(m);
    var worksheet = XLSX.utils.json_to_sheet(m);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `${name}.xlsx`);
  };

  // 

  const DeleteFile = async (name) => {
    var filteredArray = fileDisplay.filter(function (e) {
      return e !== name;
    });
    setFileDisplay(filteredArray);
    setFileNum(filteredArray.length);
    setFileName(filteredArray);
  };

  return (
    <div className="file-show">
      {deleteWarn ? (
        <div className="header deleteWarn">
          <div className="warning-header">
            <div
              className="back-btn"
              onClick={() => {
                setDeleteWarn(0);
              }}
            >
              <i class="bi bi-arrow-left-short"></i>
            </div>

            <div>Are you sure you want to delete all your files?</div>
          </div>

          <div className="controls">
            <div className="box-c">
              <div className="box">
                <img
                  src={Delete}
                  alt="delete all"
                  onClick={() => {
                    deleteAll();
                    setDeleteWarn(0);
                    setSelectAll((prev) => !prev);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="header">
          <div
            className="back-btn"
            onClick={() => navigate("/new/upload")}
          >
            <i class="bi bi-arrow-left-short"></i>
          </div>

          <h6>{fileNum ? fileNum : null} Files</h6>

          <div className="controls-c">
            <div
              className="input-group"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {
                isAdmin
                ?
                <div className="box-c">
                <div className="box">
                <i 
                className="bi bi-arrow-counterclockwise"
                onClick={() => {
                  resetUpload();
                }}
                ></i>
                  {/* <i
                    className="bi bi-box-arrow-up"
                    onClick={() => {
                      openModal();
                    }}
                  ></i> */}
                </div>
                <p>Re Upload</p>
              </div>
              :
              null
              }
              
              <div className="box-c">
                <div className="box">
                  <img src={editIcon} onClick={() => {
                    openModal();
                  }} />
                  {/* <i
                    className="bi bi-box-arrow-up"
                    onClick={() => {
                      openModal();
                    }}
                  ></i> */}
                </div>
                <p>Edit Invoice</p>
              </div>
              <div className="box-c">
                <div
                  className="box"
                  onClick={() => setSelectAll((prev) => !prev)}
                >
                  {/* {selectAll ? <img src={Check} alt="" srcset="" /> : null} */}
                </div>
                {selectAll ?
                  <p>  Deselect</p> : <p>
                    Select All
                  </p>
                }
              </div>
            </div>
            {selectAll ? (
              <div className="controls">
                <div className="box-c">
                  <div className="box">
                    <img
                      src={Save}
                      alt="save all"
                      onClick={() => downloadAll()}
                    />
                  </div>
                  <p>Zip</p>
                </div>
                <div className="box-c">
                  <div className="box">
                    <img
                      src={Save}
                      alt="save all"
                      onClick={() => downloadAllinASingleFile()}
                    />
                  </div>
                  <p>File</p>
                </div>
                <div className="box-c" onClick={() => setDeleteWarn(1)}>
                  <div className="box">
                    <img src={Delete} alt="delete all" />
                  </div>
                  <p>Delete</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
      <div className="body">
        {fileDisplay
          ? fileDisplay.map((data, key) => {
            return (
              <div className="file-div">
                <div
                  key={key}
                  className={selectAll ? "details selected" : "details"}
                >
                  <p>{data}</p>

                  {errorList && errorList.includes(data) ? (
                    <div className="error-div">
                      <p>
                        <i class="bi bi-info-circle"></i> This File needs
                        Attention.
                      </p>
                    </div>
                  ) : null}
                  <div className="icons">
                    <img
                      src={Edit}
                      alt=""
                      onClick={() => {
                        EditFile(data);
                        navigate("/new/showTable");
                      }}
                    />
                    <img
                      src={Save}
                      alt=""
                      onClick={() => {
                        download(fileData.get(data), data, key);
                      }}
                    />
                    <img
                      src={Delete}
                      alt=""
                      onClick={() => {
                        DeleteFile(data);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })
          : null}
      </div>
    </div>
  );
}

export default FileShow2;
