import React from 'react'
import "./FeatureSoon.scss"
import logoLarge from './../../Assets/npglogo.png'

function FeatureSoon() {
  return (
    <div className='feature-soon'>
        <div className='logoMain'>
          <img src={logoLarge} alt="" width={150}/>
        </div>

        <h6>Feature Coming Soon 🛠</h6>
    </div>
  )
}

export default FeatureSoon