import React from 'react'
import './Footer.scss'
import logo from './../../Assets/pf1.png' 

const Footer = () => {
  return (
    <div className='Footer'>&copy; Developed by <img src={logo} width={22} alt="" />acketFanatic</div>
  )
}

export default Footer