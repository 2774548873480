import React, { useState } from "react";
import Table from "../../Components/Table/Table";
import "./TablePage.scss";
import save from "../../Assets/Save.png";
import firebase from "firebase";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function TablePage(props) {
  const navigate = useNavigate();
  const [masterRateList, setMasterRateList] = useState([]);
  const ref = firebase.firestore().collection("Master List");

  // function getData() {
  //   const d = [];
  //   ref.onSnapshot(async (querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       const t = doc.data();
  //       const tempObj = {
  //         "Provider Name": t["Provider Name"],
  //         Rate: t["Rate"],
  //         Code: t["XCode"],
  //       };
  //       d.push(tempObj);
  //     });
  //     setMasterRateList(d);
  //   });
  // }

  useEffect(() => {
    // getData();
  }, []);

  return (
    <div className="table-page">
      <div className="btn-div flex items-center" style={{paddingBottom: "10px"}}>
        <button
          className="back-btn"
          onClick={() => navigate("/dashboard/showList")}
        >
          <i class="bi bi-arrow-left-short"></i>
        </button>

        <button onClick={() => navigate("/master-list")} style={{background: "#0762A3", padding: '10px 20px', borderRadius: "8px", color: "white", marginRight: "10px"}}>
          + Add Provider
        </button>
        <button>
          <img src={save} alt="" style={{ width: "20px", paddingBottom: "5px" }} />
          <p style={{  color: "#0762A3", fontWeight: "600" }}>Save</p>
        </button>
      </div>
      <div className="table">
        {props.data.length !== 0 && (
          <Table
            data={props.data}
            customDate={props.customDate}
            setCustomDate={props.setCustomDate}
            // masterRateList={props.data}
            nameMasterCode={props.nameMasterCode}
            invoiceNumber={props.invoiceNumber}
            fileName={props.fileName}
          />
        )}
      </div>
      <div style={{ minHeight: '100px' }}></div>
    </div>
  );
}

export default TablePage;
