import React, { useState, useEffect } from "react";
import "./Upload.scss";
import logo from "./../../Assets/uploadLogo.svg";
import FileShow from "../FileShow/FileShow";
import TablePage from "../../Pages/TablePage/TablePage";
import * as XLSX from "xlsx/xlsx.mjs";
import { set_cptable } from "xlsx";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import stringSimilarity from "string-similarity";
import Modal from "react-modal";
import * as moment from 'moment';
import { format } from 'date-fns'
import save from "./../../Assets/saveBtn.svg";
// import Calendar from 'react-calendar';
import $ from 'jquery'
import DatePicker from 'react-date-picker';

import Calendar from 'react-calendar';

import crossIcon from '../../Assets/cross-icon.svg'
import { version1, version1_file } from "../../Utils/localStorage.const";

// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

set_cptable(cptable);
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    minWidth: "550px",
    right: "auto",
    bottom: "auto",
    borderRadius: "24px",
    boxShadow:
      "-16px -16px 40px rgba(253, 255, 255, 0.8), 16px 16px 40px rgba(187, 195, 206, 0.6)",
    padding: "0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: 'none'
  },
};

function Upload(props) {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState([]);
  const [fileData, setFileData] = useState();
  const [errorList, setErrorList] = useState([]);
  const [step, setStep] = useState(1);
  const [uploaded, setUploaded] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [data, setData] = useState([]);
  const [progress, setProgress] = useState("20%");
  const [progressState, setProgressState] = useState("File Uploaded");
  const navigate = useNavigate();
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [nameMasterCode, setNameMasterCode] = useState();
  const [customDate, setCustomDate] = useState()
  let subtitle;

  const [uploadedFileData, setUploadedFileData] = useState()

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  // useEffect
  useEffect(() => {
    setProgress("20%");
    setProgressState("File Uploading");
    setUploaded(false);
  }, [location.pathname]);

  // Invoice Number modal submit triggers this function

  const InvoiceSubmit = () => {
    if (!invoiceNumber) {
      alert("Invoice Number is required");
      return;
    }
    if (!customDate) {
      alert("Date is required");
      return;
    }
    closeModal();
  };

  const handleUpload = async (e) => {
    setUploadedFileData(e.target.files[0])
    localStorage.setItem(version1_file, e.target.files[0])


    const reader = new FileReader();

    reader.onload = (event) => {
      localStorage.setItem(version1_file, event.target.result);
    }

    reader.readAsDataURL(e.target.files[0]);
    await setUploaded(true);

    openModal()
  };

  const checkInvoiceAndDate = async () => {

    var xl2json = new ExcelToJSON();
    await xl2json.parseExcel(uploadedFileData);
    progreeHandler();
  }




  useEffect(() => {
    if (invoiceNumber && customDate && uploaded) {
      checkInvoiceAndDate()
    }
  }, [invoiceNumber, customDate])

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  const progreeHandler = () => {
    setTimeout(() => {
      setProgressState("Processing the file");
      setProgress("30%");
    }, 0);
    setTimeout(() => {
      setProgressState("Detecting duplicates");
      setProgress("40%");
    }, 1000);
    setTimeout(() => {
      setProgressState("Aligning contents");
      setProgress("55%");
    }, 2000);
    setTimeout(() => {
      setProgressState("Re-evaluating everything");
      setProgress("70%");
    }, 3000);
    setTimeout(() => {
      setProgressState("Getting things done");
      setProgress("85%");
    }, 4000);
    setTimeout(() => {
      setProgressState("File is ready");
      setProgress("100%");
      // setUploaded(true);
      setStep(2);
      navigate("/dashboard/showList");
      // openModal();
    }, 5000);
  };


  const parseFile = async (json_object, invoiceNumber, customDate) => {
    let json_Array = json_object;
    json_Array.forEach(function (v) {
      if (v["Canceled Minus Appt"]) delete v["Canceled Minus Appt"];

      var d = new Date(v["Appointment Date"]);
      v["Appointment Date"] = d.toLocaleDateString();
    });


    for (const obj of json_Array) {
      try {
        const keys = Object.keys(obj);
        for (const key of keys) {
          obj[key] = obj[key].trim();
        }
      } catch (err) {
        // can ignore
      }
    }

    for (const obj of json_Array) {
      json_Array = json_Array.filter(function (e) {
        return obj !== e;
      });
      json_Array.push(obj);
    }
    // JSON.stringify(JSON.parse(json_Array));
    const unique = json_Array.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        json_Array.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    const unique_json_Array = unique.filter(
      (arr, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t["Provider Name"] === arr["Provider Name"] &&
            t["Last Name"] === arr["Last Name"] &&
            t["Appointment Date"] === arr["Appointment Date"]
        )
    );

    // provider name set and then to array
    let providerNames = new Set(
      unique_json_Array.map((item) => item["Provider Name"])
    );

    providerNames = Array.from(providerNames);
    const providerMap = new Map();

    for (const providerName of providerNames) {
      let providerData = unique_json_Array.filter(function (e) {
        return e["Provider Name"] === providerName;
      });

      providerMap.set(providerName, providerData);
    }

    const errorSet = new Set();
    let i = 0;
    for (const providerName of providerNames) {
      const [year, month, day] = customDate.split('-')
      var customdate = `${month}/${day}/${year}`
      var today = new Date(customdate);
      // today.setDate(today.getDate() + 1);
      console.log('date_enetered:', customdate);
      console.log('date:', today);
      const today_plus_15 = new Date(customDate);
      today_plus_15.setDate(today_plus_15.getDate() + 16);
      const providerData = providerMap.get(providerName);
      for (const data of providerData) {
        let found = false;
        let dataToMap;
        for (const ms of props.masterData) {
          if (!ms["ItemDescription2"]) {
          }
          var similarity = stringSimilarity.compareTwoStrings(
            'LHI - ' + ms["Location"],
            data["Provider Name"]
          );
          // const isMatch = ms["ItemDescription2"].includes(
          //   data["Last Name"]
          // );
          // if (isMatch && similarity > 0.8) {
          //   found = true;
          //   dataToMap = ms;
          //   break;
          // }
          if (similarity > 0.8) {
            found = true;
            dataToMap = ms;
            break;
          }
        }
        if (!found) {
          errorSet.add(providerName);
        }
        const invoiceAdder = parseInt(i) + parseInt(invoiceNumber)
        console.log(invoiceAdder, i);
        data["InvoiceNo"] = found ? dataToMap["XCode"] + '-' + invoiceAdder : "N.A.";
        data["Customer"] = "LHI";
        data["InvoiceDate"] = today.toLocaleDateString('en-US');
        data["DueDate"] = today_plus_15.toLocaleDateString('en-US');
        data["Terms"] = "Net 15";
        data["Location"] = found ? dataToMap["Location"] : "-";
        data["ItemDescription1"] = data["Last Name"];
        data["ItemDescription2"] = found
          ? dataToMap["ItemDescription2"]
          : "-";
        data["ItemDescription2"] = "-";
        data["Service Date"] = data["Appointment Date"];
        data["Taxable"] = "N";
        data["ItemQuantity"] = 1;
        data["Rate"] = found ? dataToMap["Rate8"] : -1;
        data["Amount"] = data["Rate"];
        data["XCode"] = found ? dataToMap["XCode"] : "N.A.";
        delete data["Provider Id"];
        delete data["Last Name"];
        delete data["Provider Name"];
        delete data["Appointment Date"];
        delete data["State"];
        try {
          delete data["Canceled Minus Appt"];
        } catch (err) {
          /// ignore
        }
      }
      i++;
    }
    const errorArray = Array.from(errorSet);

    setFileName(providerNames);
    setFileData(providerMap);
    setErrorList(errorArray);
  }

  var ExcelToJSON = function () {
    this.parseExcel = function (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          dateNF: "mm/dd/yyyy;@",
        });
        console.log("workbook", workbook);

        //logic for JSON
        try {
          const sheetNames = workbook.SheetNames;
          // parsing only first sheet

          var XL_row_object = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheetNames[0]]
          );
          console.log("workbook", XL_row_object);
          var json_object = XL_row_object;
          localStorage.setItem("version1", JSON.stringify(json_object));
          localStorage.setItem("version1_number", invoiceNumber);
          localStorage.setItem("version1_date", customDate);

          parseFile(json_object, invoiceNumber, customDate)
          // download(providerMap.get(providerNames[2]), providerNames[2]);
        } catch (err) {
          console.log(err);
        }
      };

      reader.onerror = function (ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    };
  };

  const download = (jsonArray, name) => {
    var worksheet = XLSX.utils.json_to_sheet(jsonArray);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `${name}.xlsx`);
  };

  const changeInvoice = (invoice) => {

    if (invoice.length <= 5) {
      setInvoiceNumber(invoice)
    }
  }

  const getFile = () => {
    return localStorage.getItem("version1") && localStorage.getItem("version1") != 'undefined' ? JSON.parse(localStorage.getItem("version1")) : false;
  }

  const [localFile, setLocalFile] = useState(getFile());

  useEffect(() => {
    if (localFile && props.masterData) {
      const csDate = localStorage.getItem("version1_date") && localStorage.getItem("version1_date") != 'undefined' ? localStorage.getItem("version1_date") : false;
      const invNo = localStorage.getItem("version1_number") && localStorage.getItem("version1_number") != 'undefined' ? localStorage.getItem("version1_number") : false;
      setUploaded(true)

      parseFile(getFile(), invNo, csDate);
      progreeHandler()
    }
  }, [localFile, props?.masterData])



  const resetUpload = () => {
    localStorage.removeItem("version1");
    localStorage.removeItem("version1_date");
    localStorage.removeItem("version1_number");
    window.location.reload();
    navigate("/dashboard/upload")
  }



  return (
    <div className="upload">

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="master-modal">
          <div style={{ position: 'relative', tranform: 'translate-X(20px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <div className="modal-close-icon" onClick={closeModal}><img src={crossIcon} alt="" style={{ width: "15px" }} /></div> </div>
          <div className="master-modal-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
            <input
              type="number"
              placeholder="Enter Invoice Number"
              style={{ width: "80%", fontWeight: "600" }}
              value={invoiceNumber}
              onChange={(e) => {
                changeInvoice(e.target.value)
                // setInvoiceNumber(e.target.value);
              }}
            />
            <input
              type="date"
              className="calender_input"
              placeholder="Enter Invoice Date"
              style={{ width: "80%", fontWeight: "600" }}
              value={customDate}
              onChange={(e) => {
                setCustomDate(e.target.value);
              }}
            />

            <button
              type="submit"
              // style={{}}
              onClick={() => {
                InvoiceSubmit();
              }}
            >
              {/* <img src={save} alt="save" /> */}
              <div style={{ fontWeight: "600" }}>SAVE</div>
            </button>
          </div>
        </div>
      </Modal>

      {location.pathname === "/dashboard/upload" ? (
        <div className="bg">
          <div className={uploaded ? "uploader active" : "uploader"}>
            <div className="upload-drop">
              <div className="upload-inner">
                <div className="upload-img">
                  <img src={logo} alt="logo" />
                  <input
                    type="file"
                    hidden=""
                    accept=".xlsx,.xls"
                    id="input1"
                    multiple
                    onChange={(e) => handleUpload(e)}
                  />
                </div>
              </div>
            </div>
            <div className="highlighted-txt">
              {uploaded ? (
                <p>{progressState}</p>
              ) : (
                <p>Drag and drop or browse files to upload</p>
              )}
            </div>

            {uploaded ? (
              <div className="upload-loader">
                <div className="progress-bar" style={{ width: progress }}></div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {location.pathname === "/dashboard/showList" ? (
        <div className="uploaded">
          {fileName.length === 0 ? (
            <Navigate replace to="/dashboard/upload" />
          ) : null}

          <FileShow
            setData={setData}
            setStep={setStep}
            file={file}
            fileName={fileName}
            customDate={customDate}
            fileData={fileData}
            setFileName={setFileName}
            invoiceNumber={invoiceNumber}
            errorList={errorList}
            setNameMasterCode={setNameMasterCode}
            openModal={openModal}
            resetUpload={resetUpload}
          />
        </div>
      ) : null}

      {location.pathname === "/dashboard/showTable" ? (
        <>
          {data.length === 0 ? (
            <Navigate replace to="/dashboard/upload" />
          ) : null}
          <TablePage
            data={data}
            customDate={customDate}
            setCustomDate={setCustomDate}
            setStep={setStep}
            invoiceNumber={invoiceNumber}
            fileName={fileName}
            nameMasterCode={nameMasterCode}
          />
        </>
      ) : null}
    </div>
  );
}

export default Upload;
