import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAbS6EyOUTDrT2AP0L_lLEskU5-V6cVbBs",
  authDomain: "npg-pf-8875b.firebaseapp.com",
  projectId: "npg-pf-8875b",
  storageBucket: "npg-pf-8875b.appspot.com",
  messagingSenderId: "583690615639",
  appId: "1:583690615639:web:890f5883235eec45809c12",
  measurementId: "G-6RZG3NFKZT"
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };
