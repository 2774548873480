import React from "react";
import FileShow from "../../Components/FileShow/FileShow";

function UploadedFiles() {
  return (
    <div className="uploaded-files">
      <FileShow />
    </div>
  );
}

export default UploadedFiles;
