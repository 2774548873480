import React, { useEffect } from 'react'
import './Splash.scss'
import pflogo from './../../Assets/pflogo.png'
import npglogo from './../../Assets/npglogo.png'
import plusSign from './../../Assets/plus.svg'
import { useState } from 'react'

function SplashScreen() {
    const [key,setKey] = useState(1)


    useEffect(()=>{
        setTimeout(() => {
            setKey(0)
        }, 3000);
    },[])
    return (
        <div className='SplashScreen'>
            {/* <div className="deconstructed">
                Packet Fanatic
                <div>Packet Fanatic</div>
                <div>Packet Fanatic</div>
                <div>Packet Fanatic</div>
                <div>Packet Fanatic</div>
            </div> */}
            <div className="image-holder">
                {
                    key
                    ?
                    <div className='img1'>
                        <img src={pflogo} className="i1" alt="Packet Finatic Logo" />
                        <img src={plusSign} className="i2" alt="NPG Logo" />
                    </div>
                    :
                    <div className='img2'>
                        <img src={npglogo} className="i1" alt="NPG Logo" />
                        <img src={plusSign} className="i2" alt="NPG Logo" />
                    </div>
                }
            </div>
        </div>
    )
}

export default SplashScreen