import React, { useRef } from "react";
import { auth } from "../../firebase/firebase";
import { useState, useEffect } from "react";
import autoTable from "jspdf-autotable";
import firebase from "firebase";
import save from "./../../Assets/saveBtn.svg";
import Modal from "react-modal";
import * as XLSX from "xlsx/xlsx.mjs";
import jsPDF from "jspdf";
// import axios from "axios";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from "jquery";
import "./MasterList.scss";
import Loader from "../../Components/Loader/Loader";

import editIcon from "../../Assets/edit_icon.png";

import crossIcon from "./../../Assets/cross-icon.svg";
import Footer from "../../Components/Footer/Footer";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "550px",
    right: "auto",
    bottom: "auto",
    borderRadius: "24px",
    boxShadow:
      "-16px -16px 40px rgba(253, 255, 255, 0.8), 16px 16px 40px rgba(187, 195, 206, 0.6)",
    padding: "0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

const headers = [
  "Provider Name",
  "Provider ID",
  "Practioner ID",
  "XCode",
  "4 hr Rate",
  "8 hr Rate",
  "10 hr Rate",
  "12 hr Rate",
  // "Item Description2",
  "Location",
];

function MasterList() {
  const [edit, setEdit] = useState(false);
  const ref = firebase.firestore().collection("Master List2");
  const [data, setdata] = useState([]);
  const [provName, setProvName] = useState("");
  const [provId, setProvId] = useState("");
  const [pracId, setPracId] = useState("");

  const [provRate, setProvRate] = useState("");
  const [provRate4, setProvRate4] = useState("");
  const [provRate8, setProvRate8] = useState("");
  const [provRate10, setProvRate10] = useState("");
  const [provRate12, setProvRate12] = useState("");
  const [provItemDescription2, setprovItemDescription2] = useState("");
  const [provLocation, setprovLocation] = useState("");
  const [loader, setloader] = useState(true);
  const [loading, setloading] = useState(0);
  const [npgAuth, setNpgAuth] = useState("");
  const editedList = new Set();

  const [alert, setAlert] = useState(0);

  const getUserRole = () => {
    return localStorage.getItem("role") === "admin" ? true : false;
  };

  const [isAuth, setIsAuth] = useState(getUserRole());
  // const [isAuth, setIsAuth] = useState(true)
  let items = [];
  const reference = useRef();

  //funciton which calls api for the masterRateList data
  const getData = async () => {
    console.log('yo');
    setloading(1);
    const items = [];
    await setdata([]);
    await ref.onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const t = doc.data();
        items.push(t);
      });
      setloader(false);
      setNpgAuth(auth.currentUser.email);
      $(document).ready(function () {
        setTimeout(function () {
          $("#example").DataTable().destroy();
          $("#example").DataTable({
            // searching: false,
            paging: false,
            info: false,
          });
          setloading(0);
        }, 2000);
      });
    });
    setdata(items);
  }

  useEffect(() => {
    getData();
  }, []);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const addNewProvider = async () => {
    if (!provName) {
      alert("Name is required");
      return;
    }
    if (!provRate4 || !provRate8 || !provRate10 || !provRate12) {
      alert("Rate is required");
      return;
    }
    if (!provLocation) {
      alert("Location is required");
      return;
    }

    setloading(1);

    var id = makeid(5);
    await firebase
      .firestore()
      .collection("Master List2")
      .doc(id)
      .set({
        "Provider Name": provName,
        Location: provLocation,
        ProviderID: provId,
        PractionerID: pracId,
        Rate4: parseFloat(provRate4),
        Rate8: parseFloat(provRate8),
        Rate10: parseFloat(provRate10),
        Rate12: parseFloat(provRate12),
        XCode: id,
        UID: id,
      });

    const backupData = [];

    // read all data from master rate list
    await firebase
      .firestore()
      .collection("Master List")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          backupData.push(doc.data());
        });
      });

    const doc = new jsPDF({
      encryption: {
        userPassword: "Beta@admin",
        ownerPassword: "Beta@admin",
        userPermissions: ["print", "modify", "copy", "annot-forms"],
      },
    });

    // use jsd pdf to save json data as pdf
    const body = [];
    backupData.forEach(function (bd, i) {
      body.push([
        bd["Provider Name"],
        bd["XCode"],
        bd["Rate4"],
        bd["Rate8"],
        bd["Rate10"],
        bd["Rate12"],
        bd["Location"],
      ]);
    });

    autoTable(doc, {
      head: [
        ["Provider Name", "XCode", "Rate4", "Rate8", "Rate10", "Rate12", "Location"],
      ],
      body,
    });
    await firebase
      .storage()
      .ref()
      .child("masterListBackup.pdf")
      .put(doc.output("blob"));

    setProvName("");
    setProvRate4();
    setProvRate8();
    setProvRate10();
    setProvRate12();
    setprovLocation("");
    closeModal();
    setloading(0);
    // setAlert(1);
    // getData();
    window.location.reload(false);
  };

  //function which updates the db after certain changes
  const updateAfterEdit = async () => {
    setloading(1);
    const updateArray = Array.from(editedList);
    const length = updateArray.length;
    for (let i = 0; i < length; i++) {
      try {
        const updatedDaata = data.find((o) => o.UID === updateArray[i]);
        await firebase
          .firestore()
          .collection("Master List2")
          .doc(updateArray[i])
          .set({
            "Provider Name": updatedDaata["Provider Name"],
            Location: updatedDaata.Location,
            // ItemDescription2: updatedDaata.ItemDescription2,
            ProviderID: updatedDaata.ProviderID,
            PractionerID: updatedDaata.PractionerID,
            Rate4: updatedDaata.Rate4,
            Rate8: updatedDaata.Rate8,
            Rate10: updatedDaata.Rate10,
            Rate12: updatedDaata.Rate12,
            XCode: updatedDaata.XCode,
            UID: updatedDaata.UID,
          });
      } catch (err) {
        alert("Something went wrong while updating the changes");
      }
    }
    const backupData = [];
    await firebase
      .firestore()
      .collection("Master List2")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          backupData.push(doc.data());
        });
      });

    const doc = new jsPDF({
      encryption: {
        userPassword: "Beta@admin",
        userPermissions: ["print", "modify", "copy", "annot-forms"],
      },
    });
    const body = [];
    backupData.forEach(function (bd, i) {
      body.push([
        bd["Provider Name"],
        bd["ProviderID"],
        bd["PractionerID"],
        bd["XCode"],
        bd["Rate4"],
        bd["Rate8"],
        bd["Rate10"],
        bd["Rate12"],
        bd["Location"],
      ]);
    });

    autoTable(doc, {
      head: [
        ["Provider Name", "PractionerID", "ProviderID", "XCode", "Rate4", "Rate8", "Rate10", "Rate12", "Location"],
      ],
      body,
    });

    await firebase
      .storage()
      .ref()
      .child("masterListBackup.pdf")
      .put(doc.output("blob"));

    editedList.clear();
    updateArray.length = 0;
    setEdit(false);
    setloading(0);
    setAlert(1);
  };

  //this function call for every change in data or when user edits the mater rate list
  const updateList = (e, key, value) => {
    data[key][value] = e.target.value;
    editedList.add(data[key].UID);
  };

  return (
    <div className="master-list">
      <Loader loading={loading} />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="master-modal">
          <div
            style={{
              position: "relative",
              tranform: "translate-X(20px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <div className="modal-close-icon" onClick={closeModal}>
              <img src={crossIcon} alt="" style={{ width: "15px" }} />
            </div>{" "}
          </div>
          <div
            className="master-modal-body"
            style={{ display: "flex", flexDirection: "column", minWidth: "380px", paddingLeft: "90px", paddingRight: "90px", paddingTop: '90px', paddingBottom: '90px' }}
          >
            <input
              type="text"
              placeholder="Enter Provider Name"
              onChange={(e) => {
                setProvName(e.target.value);
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <input
                type="number"
                placeholder="Enter 4 Hr Rate"
                style={{ width: '40%' }}
                onChange={(e) => {
                  setProvRate4(e.target.value);
                }}
              />
              <input
                type="number"
                placeholder="Enter 8 Hr Rate"
                style={{ width: '40%' }}
                onChange={(e) => {
                  setProvRate8(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <input
                type="number"
                placeholder="Enter 10 Hr Rate"
                style={{ width: '40%' }}
                onChange={(e) => {
                  setProvRate10(e.target.value);
                }}
              />
              <input
                type="number"
                placeholder="Enter 12 Hr Rate"
                style={{ width: '40%' }}
                onChange={(e) => {
                  setProvRate12(e.target.value);
                }}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <input
                type="number"
                placeholder="Enter Provider Id"
                style={{ width: '40%' }}
                onChange={(e) => {
                  setProvId(e.target.value);
                }}
              />
              <input
                type="number"
                placeholder="Enter Practitioner Id"
                style={{ width: '40%' }}
                onChange={(e) => {
                  setPracId(e.target.value);
                }}
              />
            </div>

            <input
              type="text"
              placeholder="Enter Location"
              onChange={(e) => {
                setprovLocation(e.target.value);
              }}
            />
            <button
              type="submit"
              style={{
                marginTop: "20px",
                maxWidth: "150px",
                width: "100px",
                alignSelf: "center",
              }}
              onClick={() => {
                addNewProvider();
              }}
            >
              {/* <img src={save} alt="save" /> */}
              <div>SAVE</div>
            </button>
          </div>
        </div>
      </Modal>

      <div
        className="custom-alert"
        style={{ opacity: alert, zIndex: alert ? "9999999" : "-1" }}
      >
        <div
          style={{
            position: "relative",
            tranform: "translate-X(20px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <div
            className="modal-close-icon"
            onClick={() => {
              setAlert(0);
            }}
          >
            <img src={crossIcon} alt="" style={{ width: "15px" }} />
          </div>{" "}
        </div>
        <div className="alert-text-wrapper">Your changes have been saved</div>
      </div>

      {/* <div className='invoice-input d-flex'>
                <input type="number" placeholder='Number of invoive XCode digits HERE' value={invoiceDigit} onChange={(e)=>{setInvoiceDigit(e.target.value)}}/>
                <button className='button-div-add' onClick={()=>{checkInvoiceDigit()}}>SAVE</button>
            </div> */}
      <br />
      {loader === false && (
        <>
          {isAuth ? (
            <div
              className="button_div master-table-top sticky"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              {edit ? (
                <button
                  className="button-div-add add-provider"
                  style={{
                    backgroundColor: "#E4E4E4",
                    color: "#0762a3",
                    cursor: " not-allowed",
                    fontWeight: "600",
                  }}
                >
                  + Add New Provider
                </button>
              ) : (
                <button
                  onClick={openModal}
                  className="button-div-add add-provider"
                  style={{ fontWeight: "600" }}
                >
                  + Add New Provider
                </button>
              )}

              <h3
                style={{
                  color: "#0762a3",
                  position: "absolute",
                  width: "100%",
                  textAlign: "center",
                  display: "inline",
                  zIndex: "-1",
                }}
              >
                Master Rate List
              </h3>

              {edit ? (
                <button
                  style={{ alignSelf: "flex-end" }}
                  onClick={updateAfterEdit}
                >
                  {" "}
                  <img
                    src={save}
                    alt="save"
                    style={{
                      height: "40%",
                      margin: "auto",
                      height: "15px",
                      paddingBottom: "3px",
                    }}
                  />{" "}
                  <p style={{ fontWeight: "600" }}>Save</p>
                </button>
              ) : (
                <button
                  onClick={() => setEdit(true)}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={editIcon}
                    alt="save"
                    style={{
                      height: "40%",
                      margin: "auto",
                      height: "15px",
                      paddingBottom: "3px",
                    }}
                  />{" "}
                  <p style={{ fontWeight: "600" }}>Edit</p>
                </button>
              )}
            </div>
          ) : null}

          <table
            className=" display table"
            id="example"
            style={{ marginTop: "20px" }}
          >
            <thead>
              <tr>
                {headers
                  ? headers.map((data, key) => {
                    return (
                      <th scope="col" key={key}>
                        <p>{data}</p>
                      </th>
                    );
                  })
                  : null}
              </tr>
            </thead>
            <tbody>
              {loader === false &&
                data.map((invoices, index) => {
                  return (
                    <tr
                      className={index % 2 !== 0 ? "color" : "non-color"}
                      key={index}
                    >
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            {" "}
                            <input
                              type="text"
                              defaultValue={invoices["Provider Name"]}
                              onChange={(e) => {
                                updateList(e, index, "Provider Name");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices["Provider Name"]}</p>
                        )}
                      </td>
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            {" "}
                            <input
                              type="text"
                              defaultValue={invoices["ProviderID"]}
                              onChange={(e) => {
                                updateList(e, index, "ProviderID");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices["ProviderID"]}</p>
                        )}
                      </td>
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            {" "}
                            <input
                              type="text"
                              defaultValue={invoices["PractionerID"]}
                              onChange={(e) => {
                                updateList(e, index, "PractionerID");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices["PractionerID"]}</p>
                        )}
                      </td>
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            {" "}
                            <input
                              type="text"
                              defaultValue={invoices["XCode"]}
                              onChange={(e) => {
                                updateList(e, index, "XCode");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices.XCode}</p>
                        )}
                      </td>
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            <input
                              type="number"
                              defaultValue={invoices["Rate4"]}
                              onChange={(e) => {
                                updateList(e, index, "Rate4");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices.Rate4}</p>
                        )}
                      </td>
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            <input
                              type="number"
                              defaultValue={invoices["Rate8"]}
                              onChange={(e) => {
                                updateList(e, index, "Rate8");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices.Rate8}</p>
                        )}
                      </td>
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            <input
                              type="number"
                              defaultValue={invoices["Rate10"]}
                              onChange={(e) => {
                                updateList(e, index, "Rate10");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices.Rate10}</p>
                        )}
                      </td>
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            <input
                              type="number"
                              defaultValue={invoices["Rate12"]}
                              onChange={(e) => {
                                updateList(e, index, "Rate12");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices.Rate12}</p>
                        )}
                      </td>
                      {/* <td>
                        {edit ? (
                          <p className="td-input-parent">
                            <input
                              type="text"
                              defaultValue={invoices["ItemDescription2"]}
                              onChange={(e) => {
                                updateList(e, index, "ItemDescription2");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices.ItemDescription2}</p>
                        )}
                      </td> */}
                      <td>
                        {edit ? (
                          <p className="td-input-parent">
                            <input
                              type="text"
                              defaultValue={invoices["Location"]}
                              onChange={(e) => {
                                updateList(e, index, "Location");
                              }}
                            />
                          </p>
                        ) : (
                          <p>{invoices.Location}</p>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Footer />
        </>
      )}
    </div>
  );
}

export default MasterList;
