import React from "react";
import { db, auth } from "../../firebase/firebase";
import { useState, useEffect } from "react";
import firebase from "firebase";
import "./UserList.scss";
import save from "./../../Assets/saveBtn.svg";
import Modal from "react-modal";

import Loader from "../../Components/Loader/Loader"

import crossIcon from '../../Assets/cross-icon.svg'
import Footer from "../../Components/Footer/Footer";

// import { db, auth } from "../../firebase/firebase";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        minWidth: "450px",
        right: "auto",
        bottom: "auto",
        borderRadius: "24px",
        boxShadow:
            "-16px -16px 40px rgba(253, 255, 255, 0.8), 16px 16px 40px rgba(187, 195, 206, 0.6)",
        padding: "0px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        overflow: 'none'
    },
};

const headers = [
    "First Name",
    "Last Name",
    "Email",
    "Role"
];

function UserList() {
    const [edit, setEdit] = useState(false);
    const ref = firebase.firestore().collection("user");
    const [data, setdata] = useState([]);
    // const [loader, setloader] = useState(true);
    const [npgAuth, setNpgAuth] = useState("");
    const editedList = new Set();

    const [loading, setLoading] = useState(false)


    const [user, setUser] = useState({
        fName: "",
        lName: "",
        email: "",
        password: "",
        isAdmin: false
    })


    //funciton which calls api for the masterRateList data
    async function getData() {
        setLoading(true)

        const items = [];
        setdata([]);
        await ref.onSnapshot((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const t = doc.data();
                items.push(t);
            });
            // setloader(false);
            setNpgAuth(auth.currentUser.email);
            setLoading(false)
        });
        setdata(items);

    }

    useEffect(() => {
        getData();
    }, []);

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }


    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = "#f00";
    }


    function closeModal() {
        setIsOpen(false);
    }


    async function addUser() {

        if (!user?.email || !user?.fName || !user?.lName || !user?.password) {
            return alert("Please fill all details")
        }

        setLoading(true)


        await auth
            .createUserWithEmailAndPassword(user?.email, user?.password)
            .then((cred) => {
                setLoading(false)


                alert("User created successfully");
                closeModal();

                const obj = {
                    name: user?.fName,
                    lastname: user?.lName,
                    email: user?.email,
                    isAdmin: user?.isAdmin
                }



                db.collection("user").doc(cred.user.uid).set({
                    name: user?.fName,
                    lastname: user?.lName,
                    email: user?.email,
                    isAdmin: user?.isAdmin
                });

                return setdata([...data, obj])


            })
            .catch((error) => {
                setLoading(false)
                let errorCode = error.code;
                if (errorCode === "auth/email-already-in-use") {
                    alert("Email Already Exists!");

                } else if (errorCode === "auth/invalid-email") {
                    alert("Please Enter a Valid mail id");
                } else if (
                    (errorCode === "auth/invalid-password") |
                    (errorCode === "auth/weak-password")
                ) {
                    alert("Enter a Strong Password");
                }
            });


    }

    return (
        <div className="user-list">
            <Loader loading={loading} />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="master-modal">
                    <div style={{ position: 'relative', tranform: 'translate-X(20px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <div className="modal-close-icon" onClick={closeModal}><img src={crossIcon} alt="" style={{width:"15px"}} /></div> </div>
                    <div className="master-modal-body" style={{ display: "flex", flexDirection: "column", minWidth: "380px", paddingLeft: "100px", paddingRight: "100px",paddingTop:'50px',paddingBottom:'50px' }}>
                        <div style={{ display: "flex",justifyContent:'end',alignItems:'center' }}>

                            <label style={{ color: 'white' }}>Admin?</label>

                            <input
                                type="checkbox"
                                style={{ background: "transparent" }}
                                value={user?.isAdmin}
                                className="input-check"
                                onChange={(e) => {
                                    setUser({ ...user, isAdmin: !user?.isAdmin });
                                }}
                            />
                        </div>
                        <input
                            type="text"
                            placeholder="Enter First Name"
                            value={user?.fName}
                            onChange={(e) => {
                                setUser({ ...user, fName: e.target.value });

                            }}
                        />
                        <input
                            type="text"
                            placeholder="Enter Last Name"
                            value={user?.lName}
                            onChange={(e) => {
                                setUser({ ...user, lName: e.target.value });

                            }}
                        />
                        <input
                            type="text"
                            placeholder="Enter Email"
                            value={user?.email}
                            onChange={(e) => {
                                setUser({ ...user, email: e.target.value });
                            }}
                        />
                        <input
                            type="text"
                            placeholder="Enter Password"
                            value={user?.password}
                            onChange={(e) => {
                                setUser({ ...user, password: e.target.value });
                            }}
                        />


                        <button
                            type="submit"
                            style={{marginTop:'20px',maxWidth:'150px',width:'100px',alignSelf:'center'}}
                            onClick={() => {
                                addUser()
                                // addNewProvider();
                            }}
                        >
                            SAVE                        </button>
                    </div>
                </div>
            </Modal>

            {/* <div className="button_div" style={{ marginTop: "30px" }}>
                <button onClick={() => openModal()} className="button-div-add">
                    ADD USER
                </button>
            </div> */}
            <div style={{minHeight:'94vh', maxHeight:'94vh', overflow:'auto'}}>
            <div className="button_div">
                <button onClick={() => openModal()} className="button-div-add add-provider" style={{ fontWeight: "600" }}>
                    + Add New Admin
                </button>
            </div>


            <>
                <table className="table">
                    <thead>
                        <tr>
                            {headers
                                ? headers.map((data, key) => {
                                    return (
                                        <th scope="col" key={key}>
                                            <p>{data}</p>
                                        </th>
                                    );
                                })
                                : null}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((user, index) => {
                            return (<tr
                                className={index % 2 !== 0 ? "color" : "non-color"}
                                key={index}
                            >
                                <td>
                                    <input
                                        type="text"
                                        value={user.name}
                                        onChange={(e) => {
                                            // updateList(e, index, "Location");
                                        }}
                                    />

                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={user.lastname}
                                        onChange={(e) => {
                                            // updateList(e, index, "Location");
                                        }}
                                    />

                                </td>

                                <td>
                                    <input
                                        type="text"
                                        value={user.email}
                                        onChange={(e) => {
                                            // updateList(e, index, "Location");
                                        }}
                                    />
                                </td>

                                <td>

                                    <input
                                        type="text"
                                        value={user.isAdmin ? "admin" : "user"}
                                        onChange={(e) => {
                                            // updateList(e, index, "Location");
                                        }}
                                    />
                                </td>
                            </tr>
                            );
                        })}
                    </tbody>
                </table>

            </>

            </div>

            <div style={{minHeight:'6vh',display:'flex',alignItems:'center', justifyContent:'center'}}>
                <Footer/>
            </div>

        </div>
    );
}

export default UserList;
