import React from 'react'
import "./NotFound.scss"

function NotFound() {
  return (
    <div className='not-found'>
        <h6>404 Page Not Found</h6>
    </div>
  )
}

export default NotFound